import classNames from "classnames/bind";
import React, {ReactElement, useMemo} from "react";

import {Avatar} from "../../../shared/v2";
import {useThemeMode} from "../../../context/theme-mode-context";
import {Persona} from "../../../models";
import {AdditionalAgentsAvatar} from "./additional-agents-avatar";

import styles from "./personas-list.module.scss";

const cx = classNames.bind(styles);

export interface PersonasListProps {
  personas: Persona[];
  className?: string;
  size: "sm" | "md";
  max: number;
  variant?: "history" | "card"
}

export const PersonasList = ({personas, className, size, max = 0, variant = "history"}: PersonasListProps): ReactElement => {
  const {isDarkMode} = useThemeMode();

  const isOverflow = personas.length > max;

  const sizeMap = {
    sm: 32,
    md: 40,
  }

  const content = useMemo(() => {
    const content = [
      ...personas.slice(0, isOverflow ? max - 1 : max).map((persona, index) => {
        return <Avatar
          key={index}
          size={size}
          firstName={persona?.name}
          url={persona?.picture?.url}
          className={styles.avatar}
        />;
      })
    ]

    if (isOverflow) {
      content.push(
        <AdditionalAgentsAvatar
          size={size}
          text={`+${personas.length - max + 1}`}
          key={max}
        />
      )
    }

    return content;
  }, [personas, isOverflow, max, size]);

  const width = useMemo(() => {
    if (content.length) {
      return (sizeMap[size] * content.length) - (8 * (content.length - 1)) + 4;
    }
  }, [content.length, size]);

  if (content.length === 0) {
    return <></>;
  }

  return (
    <div
      className={cx("teams", `size-${size}`, className, variant, {isDarkMode})}
      style={{width}}
    >
      {content}
    </div>
  )
}
