import React, {ReactElement} from "react"
import {Outlet} from "react-router";

import {TopBar} from "../../components/topbar";
import {WorkflowProvider, WorkflowEventsProvider, CarouselScrollProvider, WorkflowModalsProvider} from "../../../context/workflow-contexts";
import {RunWorkflowContextProvider} from "../../../context/workflow-contexts/run-workflow-context";

import styles from "./flow-page.module.scss"

export const FlowPage = (): ReactElement => {

	return (
		<CarouselScrollProvider>
			<WorkflowProvider>
				<RunWorkflowContextProvider>
					<WorkflowEventsProvider>
						<WorkflowModalsProvider>
							<div className={styles.layout}>
								<TopBar />
								<Outlet  />
							</div>
						</WorkflowModalsProvider>
					</WorkflowEventsProvider>
				</RunWorkflowContextProvider>
			</WorkflowProvider>
		</CarouselScrollProvider>
	);
}
