import React, {ReactElement} from "react";
import {useMutation} from "@apollo/client";
import classNames from "classnames/bind";

import {Body, Button} from "../../../shared/v2";
import {DocumentsFolderFileIcon, HornMegaphoneCampaignIcon, PlusSmallIcon, SourcesIcon} from "../../../icons";
import {Campaign, TrainingSet} from "../../../models/ai-model";
import {isTrainingSet} from "../../../canvas/chat-bubble/sources-section";
import {InputChip} from "../../../shared/components/input-chip";
import {AiOrchestration} from "../../../models/ai-orchestration";
import {UPDATE_AI_ORCHESTRATION} from "../../../graphql/mutations/ai-mutations";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./sources-card.module.scss";

const bStyles = classNames.bind(styles);

export interface SourcesCardProps {
  workflow: AiOrchestration;
  onUpdate?: () => void;
  openSourcesModal: () => void;
  currentSources: (Campaign | TrainingSet)[];
	disabled?: boolean;
}

export const SourcesCard = ({workflow, onUpdate, openSourcesModal, currentSources, disabled}: SourcesCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [updateWorkflow] = useMutation(UPDATE_AI_ORCHESTRATION);

	const renderSource = (source: TrainingSet | Campaign) => {
		if (isTrainingSet(source)) {
			return (
				<InputChip
					className={styles.source}
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => handleRemoveSource(source)}
					label={source.alias}
					variant="squared"
					size="small"
					showCloseIcon={!disabled}
				/>
			);
		}

		return (
			<InputChip
				className={styles.source}
				variant="squared"
				icon={<HornMegaphoneCampaignIcon />}
				key={source.id}
				onRemove={() => handleRemoveSource(source)}
				label={source.name}
				size="small"
				showCloseIcon={!disabled}
			/>
		);
	};


	const renderSourcesOrPlaceholder = (): ReactElement => {
		if (currentSources.length === 0) {
			return <Body className={styles.placeholder}>
        Add datasets, campaigns, or other sources to enhance your workflow (optional).
			</Body>
		}

		return <div className={styles.sourcesList}>
			{currentSources.map(renderSource)}
		</div>
	}


	const handleRemoveSource = (source: TrainingSet | Campaign): void => {
		updateWorkflow({
			variables: {
				id: workflow.id,
				trainingSetIds: currentSources.filter(s => s.id !== source.id).filter(isTrainingSet).map(s => s.id),
				surveyIds: currentSources.filter(s => s.id !== source.id).filter(s => !isTrainingSet(s)).map(s => s.id)
			}
		})
		onUpdate?.();
	}

	return <>
		<div className={bStyles("container", {isDarkMode})}>
			<div className={styles.headerWrapper}>
				<SourcesIcon className={styles.icon} />
				<Body type="medium">Sources</Body>
			</div>
			<div className={styles.content}>
				{renderSourcesOrPlaceholder()}
			</div>

			{!disabled &&
				<Button
					size="small"
					variant="text"
					leftIcon={<PlusSmallIcon />}
					onClick={openSourcesModal}

				>
					Add Source
				</Button>
			}
		</div>
	</>
}
