import React, {useEffect, useLayoutEffect, useRef} from "react";
import {motion} from "framer-motion";
import classNames from "classnames/bind";

import {AgentTaskContainer} from "../../components/agent-task-container";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {Spinner} from "../../../shared/v2";
import {ObjectiveCard} from "../../components/objective-card";
import {SourcesCard} from "../../components/sources-card";
import {FlowOutputCard} from "../flow-output-card";
import {Connector} from "../connector";
import {useWorkflowContext, useCarouselScrollContext, useWorkflowModalsContext, useRunWorkflowContext} from "../../../context/workflow-contexts";
import {WorkflowCanvasButton} from "../workflow-canvas-button";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./workflow-canvas.module.scss";

const bStyles = classNames.bind(styles);

export interface WorkflowCanvasProps {
	disabled?: boolean;
	agents?: AiPersonaTask[];
}

export const WorkflowCanvas = ({disabled, agents}: WorkflowCanvasProps) => {
	const {isDarkMode} = useThemeMode();
	const {
		workflow,
		agentTasks,
		setAgentTasks,
		currentSources,
		isLoadingWorkflow,
		currentHistory,
		isOnHistoryTab,
		reportUrl,
		isWorkflowDirty,
		currentHistorySources,
	} = useWorkflowContext();

	const {isWorkflowRunning} = useRunWorkflowContext();

	const {setIsSelectAgentModalOpen, setIsSourcesModalOpen} = useWorkflowModalsContext();
	const constraintsRef = useRef<HTMLDivElement>(null);
	const grabbingRef = useRef<HTMLDivElement>(null);
	const lastElementRef = useRef<HTMLDivElement>(null);

	const {x, scrollToElement} = useCarouselScrollContext();

	useLayoutEffect(() => {
		if (agents && lastElementRef.current) {
			scrollToElement(agents[agents.length - 1].id);
		}
	}, [agents?.length, lastElementRef.current]);

	// used for changing the cursor when dragging
	useEffect(() => {
    const container = grabbingRef.current;

    const handleMouseDown = () => {
      if (container) {
        container.classList.add(styles.grabbing);
      }
    };

    const handleMouseUp = () => {
      if (container) {
        container.classList.remove(styles.grabbing);
      }
    };

    if (container) {
      container.addEventListener("mousedown", handleMouseDown);
      container.addEventListener("mouseup", handleMouseUp);
      container.addEventListener("mouseleave", handleMouseUp);
    }

    return () => {
      if (container) {
        container.removeEventListener("mousedown", handleMouseDown);
        container.removeEventListener("mouseup", handleMouseUp);
        container.removeEventListener("mouseleave", handleMouseUp);
      }
    };
  }, []);

	const handleTaskChange = (agent: AiPersonaTask, value: string) => {
		setAgentTasks(
			agentTasks.map(item => {
				if (item.id === agent.id) {
					return {...item, operation: "UPDATE", task: {taskPrompt: value}};
				}
				return item;
			}),
		);
	};

	const handleRemoveAgent = agentTask => {
		if (agentTask.id.startsWith("NEW")) {
			setAgentTasks(agentTasks.filter(a => a.id !== agentTask.id));
			return;
		}

		agentTask.operation = "DELETE";
		setAgentTasks(agentTasks.map(item => (item.id === agentTask.id ? agentTask : item)));
	};

	const handleAddAgent = () => {
		setIsSelectAgentModalOpen(true);
	};

	const handleIndexChange = (agent: AiPersonaTask, newIndex: number) => {
		setAgentTasks(
			agentTasks.map(item => {
				if (item.id === agent.id) {
					item = {...item, index: newIndex, operation: "UPDATE"};
					return item;
				}
				return item;
			}),
		);
	};

	const renderWorkflow = () => {
		if (isLoadingWorkflow) {
			return (
				<div className={styles.loadingContainer}>
					<Spinner className={styles.spinner} />
				</div>
			);
		}
		if (!workflow) {
			return null;
		}

		return (
			<>
				<ObjectiveCard objective={workflow?.description} />
				<motion.div
					className={styles.carouselContent}
					drag={"x"}
					dragConstraints={constraintsRef}
					id="workflow-carousel"
					style={{x}}
					ref={grabbingRef}
				>
					{isOnHistoryTab &&
						<SourcesCard
							workflow={workflow}
							openSourcesModal={() => setIsSourcesModalOpen(true)}
							currentSources={currentHistorySources}
							disabled={disabled}
						/>
					}
					{!isOnHistoryTab &&
						<SourcesCard
							workflow={workflow}
							openSourcesModal={() => setIsSourcesModalOpen(true)}
							currentSources={currentSources}
							disabled={disabled}
						/>
					}

					{agents?.map((agent, index) => (
						<AgentTaskContainer
							key={agent.id}
							agentTask={agent}
							onRemove={handleRemoveAgent}
							onChangeTask={value => handleTaskChange(agent, value)}
							onChangeIndex={handleIndexChange}
							disabled={disabled}
							elementRef={index === agents.length - 1 ? lastElementRef : null}
						/>
					))}


					{isOnHistoryTab &&
						<>
							<Connector disabled={disabled} />
							<FlowOutputCard reportUrl={currentHistory?.reportUrl} />
						</>
					}

					{!isOnHistoryTab && reportUrl && !isWorkflowDirty &&
						<>
							<Connector />
							<FlowOutputCard reportUrl={reportUrl} />
						</>
					}

					{!disabled &&
						<WorkflowCanvasButton
							className={styles.addAgentButton}
							onClick={handleAddAgent}
							disabled={isWorkflowRunning}
						/>
					}
				</motion.div>
			</>
		);
	};

	return (
		<div
			className={bStyles("canvas", {isDarkMode})}
			ref={constraintsRef}
		>
			{renderWorkflow()}
		</div>
	);
};

