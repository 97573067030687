import React, {ReactElement, useMemo} from "react";
import classNames from "classnames/bind";

import {EyePreviewIcon, FileTextIcon, HandOutputStarIcon} from "../../../icons";
import {Body, Button} from "../../../shared/v2";
import {AssistiveChip} from "../../../shared/v2/assistive-chip";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./flow-output-card.module.scss";

const cx = classNames.bind(styles);

export interface FlowOutputCardProps {
	reportUrl?: string;
}

export const FlowOutputCard = ({reportUrl}: FlowOutputCardProps): ReactElement | null => {
	const {isDarkMode} = useThemeMode();

	const reportName = useMemo(() => {
		if (!reportUrl) {
			return "";
		}
		const parts = reportUrl.split("/");
    return parts[parts.length - 1];
	} , [reportUrl]);

	if (!reportUrl) {
		return null;
	}

  return (
		<div className={cx("container", {isDarkMode})}>
			<div className={styles.headerWrapper}>
				<HandOutputStarIcon className={styles.headerIcon} />
				<Body type="medium">Output</Body>
			</div>
			<div className={styles.content}>
				<AssistiveChip
					className={styles.outputChip}
					text={reportName}
					icon={<FileTextIcon />}
				/>
			</div>

			<div className={styles.actions}>
				<Button
					leftIcon={<EyePreviewIcon />}
					variant="outlined"
					onClick={() => {
						window.open(reportUrl, "_blank");
					}}
					size="small"
				>
					Preview
				</Button>
			</div>
		</div>
  );
}
