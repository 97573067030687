import React, {ReactElement} from "react";

import {Connector} from "../connector";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {OutputCard} from "../output-card";
import {AgentTaskCard} from "../agent-task-card";

import styles from "./agent-task-container.module.scss";

export interface AgentTaskContainerProps {
	agentTask: AiPersonaTask;
	onRemove: (agent: AiPersonaTask) => void;
	onChangeTask: (value: string) => void;
	onChangeIndex: (agent: AiPersonaTask, newIndex: number) => void;
	disabled?: boolean;
	elementRef?: any;
}

export const AgentTaskContainer = ({
	agentTask,
	onRemove,
	onChangeTask,
	onChangeIndex,
	disabled,
	elementRef,
}: AgentTaskContainerProps): ReactElement => {

	return (
		<>
			<Connector disabled={disabled} />
			<div className={styles.agentTaskContainer} id={agentTask.id} ref={elementRef}>
				<AgentTaskCard
					agentTask={agentTask}
					onRemove={onRemove}
					onChangeTask={onChangeTask}
					onChangeIndex={onChangeIndex}
					disabled={disabled}
				/>

				<OutputCard agentTask={agentTask} />
			</div>
		</>
	);
};
