import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {AiPersonaTask} from "../../../models/ai-orchestration";
import {Avatar, Body, Input, Subheader, TextArea} from "../../../shared/v2";
import {CloseMediumIcon} from "../../../icons";
import {useRunWorkflowContext} from "../../../context/workflow-contexts/run-workflow-context";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./agent-task-card.module.scss";

const bStyles = classNames.bind(styles);

export interface AgentTaskCardProps {
	agentTask: AiPersonaTask;
	onRemove: (agent: AiPersonaTask) => void;
	onChangeTask: (value: string) => void;
	onChangeIndex: (agent: AiPersonaTask, newIndex: number) => void;
	disabled?: boolean;
}

export const AgentTaskCard = ({agentTask, onRemove, onChangeTask, onChangeIndex, disabled}: AgentTaskCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {isWorkflowRunning} = useRunWorkflowContext();

	const handleChangeTask = (value: string) => {
		onChangeTask(value);
	};

	const handleRemove = () => {
		if (isWorkflowRunning) {
			return;
		}
		onRemove(agentTask);
	};

	const cardStyles = bStyles("card", {
		processing: agentTask.processingState?.toLowerCase().includes("processing"),
		completed: agentTask.processingState?.toLowerCase().includes("completed"),
		isDarkMode,
	});

	return (
		<div className={cardStyles}>
			<div className={styles.topSection}>
				<Avatar key={agentTask.persona?.id} url={agentTask.persona?.picture?.url} size="xxl" />
				<div className={styles.generalInfo}>
					<Subheader className={styles.name} type="semibold">
						{(agentTask.persona?.name || "")
							.replace(/-auto-.*$/, "")
							.split(/(?=[A-Z])/)
							.join(" ")}
					</Subheader>
					<Body size="s" color="text-secondary">
						{agentTask.persona?.personaType?.name}
					</Body>
				</div>
				{!disabled &&
					<CloseMediumIcon onClick={handleRemove} className={bStyles("closeIcon", {isWorkflowRunning})} />
				}
			</div>

			<TextArea
				className={styles.task}
				value={agentTask.task?.taskPrompt || ""}
				onChange={handleChangeTask}
				label="Task"
				disabled={isWorkflowRunning || disabled}
			/>

			<div className={styles.bottomSection}>
				<span />
				{/* <Button
					size="small"
					variant="text"
					leftIcon={<TestingLabTestResultIcon />}
					disabled={isWorkflowRunning}
				>
					Test Output
				</Button> */}
				<Input
					type="number"
					label="Order"
					value={(agentTask.index + 1).toString()}
					onChange={value => {
						const newIndex = parseInt(value, 10) - 1;
						if (!isNaN(newIndex) && newIndex >= 0) {
							onChangeIndex(agentTask, newIndex);
						}
					}}
					min={1}
					disabled={isWorkflowRunning || disabled}
					className={styles.indexInput}
				/>
			</div>
		</div>
	);
};
