import React, {ReactElement, useEffect, useRef, useState} from "react";
import classNames from "classnames/bind";

import {ArrowSplitIcon, ChevronDownIcon} from "../../../icons";
import {Body} from "../../../shared/v2";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {AutoResizeTextarea} from "../../../shared/v2/inputs";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./output-card.module.scss";

const bStyles = classNames.bind(styles);

export interface OutputCardProps {
  agentTask: AiPersonaTask;
}

export const OutputCard = ({agentTask}: OutputCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		if (agentTask.output?.length) {
			setIsExpanded(true);
		}
	}, [agentTask.output]);

	const cardStyles = bStyles("outputWrapper", {
		completed: agentTask.processingState?.toLowerCase().includes("completed"),
		isDarkMode,
	});

	return <div className={cardStyles}>
		<div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
			<div className={styles.leftSide}>
				<ArrowSplitIcon className={styles.arrowIcon} />
				<Body type="medium">Output</Body>
			</div>

			<ChevronDownIcon className={styles.chevronIcon} />
		</div>

		{isExpanded && <div className={styles.content}>
			<AutoResizeTextarea value={agentTask.output || ""} onChange={() => {}} readOnly />
		</div>}
	</div>
}
