import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Body, Separator} from "../../../shared/v2";
import {useThemeMode} from "../../../context/theme-mode-context";
import {VurveyYellowLogoIcon} from "../../../icons";

import styles from "./agent-os-section.module.scss";

const cx = classNames.bind(styles);

export interface AgentOsSectionProps {
  label: string;
  systems: {
    version: string;
    name: string;
  }[];
  description: string;
}

export const AgentOsSection = (props: AgentOsSectionProps): ReactElement => {
  const {isDarkMode} = useThemeMode();

  return (
    <div className={cx("agentOsSection", {isDarkMode})}>
      <Body type="semibold" className={styles.label}>
        {props.label}
      </Body>

      <div className={styles.systems}>
        {props.systems.map((system, index) => (
          <>
            {index !== 0 && <Separator className={styles.separator} />}

            <div key={index} className={styles.system}>
              <div className={styles.systemNameWrapper}>
                <VurveyYellowLogoIcon className={styles.vurveyLogo} />
                <Body size="s" className={styles.name}>
                  {system.name}
                </Body>
              </div>
              <Body size="s" className={styles.version}>
                Version {system.version}
              </Body>
            </div>
          </>
        ))}
      </div>

      <Body color="text-secondary" className={styles.description}>
        {props.description}
      </Body>
    </div>
  );
}
