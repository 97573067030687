import {useMutation, useQuery} from "@apollo/client";
import classNames from "classnames/bind";
import React, {ReactElement, useCallback, useMemo, useState} from "react";

import {groupBy} from "lodash-es";
import {useAgentsPageContext} from "../../../context/agents-page-context";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useToastContext} from "../../../context/toast-context";
import {DELETE_PERSONA} from "../../../graphql/mutations/persona-mutations";
import {AI_PERSONA_TYPES, AI_SKILLS} from "../../../graphql/queries/ai-models-queries";
import {useSameHeight} from "../../../hooks";
import {useMount} from "../../../hooks/useMount";
import {useObserver} from "../../../hooks/useObserver";
import {DeletePersonaReturn, ParsedInstructions, Persona, PersonaModel} from "../../../models/persona";
import {useNavigate} from "../../../route";
import {LoadingContainer, MultiSelectInput, SearchInput, SelectInput} from "../../../shared/v2";
import {Option} from "../../../shared/v2/inputs/select-input";
import {ConfirmActionModal} from "../../../shared/v2/modals/confirm-action-modal";
import {AgentDrawer} from "../../components/agent-drawer";
import {AgentsSection} from "../../components/v2/agents-section";

import styles from "./assistants-page.module.scss";

const cx = classNames.bind(styles);

const getTime = (date: string): number => new Date(date).getTime();

export const AssistantsPage = (): ReactElement => {
	const navigate = useNavigate();
	const {updateToast} = useToastContext();
	const {isDarkMode} = useThemeMode();

	const [searchValue, setSearchValue] = useState("");
	const [idForDelete, setIdForDelete] = useState("");
	const [sortValue, setSortValue] = useState("newest");
	const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
	const [selectedModels, setSelectedModels] = useState<string[]>([]);
	const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
	const [selectedStatus, setSelectedStatus] = useState("");

	const {agents: personas, isLoading: loading} = useAgentsPageContext();

	const {data: agentTypesData} = useQuery(AI_PERSONA_TYPES, {
		fetchPolicy: "cache-first",
	});

	const {data: tagsData} = useQuery(AI_SKILLS, {
		fetchPolicy: "cache-first",
	});

	const [deletePersona] = useMutation<DeletePersonaReturn, {id: string}>(DELETE_PERSONA);

	useMount(() => {document.title = "Vurvey - Agents"});

	const handleSearchChange = (value: string): void => setSearchValue(value);

	const handleEdit = (persona: Persona): void => {
		navigate(`/agents/builder?id=${persona.id}`);
	};

	const handleOpenDeleteModal = (id: string): void => setIdForDelete(id);

	const handleDelete = (): void => {
		deletePersona({
			variables: {id: idForDelete},
			onCompleted: () => {
				updateToast({description: "Deleted Agent", type: "informational"});
				setIdForDelete("");
			},
			onError: () => {
				updateToast({description: "Failed to delete, please try again later", type: "failure"});
				setIdForDelete("");
			},
			update(cache, {data}) {
				if (!data) return;
				cache.modify({
					fields: {
						aiPersonasForWorkspace(existing = [], {readField}) {
							const copiedPersonas = [...existing];
							const updatedPersonas = copiedPersonas.filter(
								ref => data.deleteAiPersona.id !== readField("id", ref),
							);
							return updatedPersonas;
						},
					},
				});
				cache.evict({id: `AiPersona:${data.deleteAiPersona.id}`, broadcast: false});
			},
		});
	};

	const personasWithParsedInstructions: Persona[] = useMemo(() => {
		return personas.map((persona) => {
			const parsedInstructions: ParsedInstructions[] = JSON.parse(persona.instructions);
			return {...persona, parsedInstructions: parsedInstructions?.[0]};
		});
	}, [personas])

	const filteredByStatus = useMemo(() => {
		if (!selectedStatus) {
			return personasWithParsedInstructions;
		}
		return personasWithParsedInstructions.filter((persona: Persona) => {
			return persona.personaStatus === selectedStatus;
		});
	}, [selectedStatus, personasWithParsedInstructions]);

	const filteredByType = useMemo(() => {
		if (selectedTypes.length === 0) {
			return filteredByStatus;
		}
		return filteredByStatus.filter((persona: Persona) => {
			return selectedTypes.includes(persona.personaType.name);
		});
	}, [filteredByStatus, selectedTypes])

	const filteredByModel = useMemo(() => {
		if (selectedModels.length === 0) {
			return filteredByType;
		}
		return filteredByType.filter((persona: Persona) => {
			return persona.parsedInstructions?.model && selectedModels.includes(persona.parsedInstructions.model);
		});
	}, [filteredByType, selectedModels])

	const filteredBySkills = useMemo(() => {
		if (selectedSkills.length === 0) {
			return filteredByModel;
		}

		return filteredByModel.filter((persona: Persona) => {
			return persona?.skills && selectedSkills.some((skill) => persona.skills.map(sk => sk.id).includes(skill));
		});
	}, [filteredByModel, selectedSkills])


	const filteredPersonas = useMemo(() => {
		return filteredBySkills.filter((persona: Persona) => {
			return persona.name.toLowerCase().includes(searchValue.toLowerCase());
		});
	}, [searchValue, filteredBySkills]);

	const sortPersonas = useCallback((personas: Persona[]): Persona[] => {
		if (sortValue === "newest") {
			return personas.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
		}
		return personas.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
	}, [sortValue]);

	const isTrending = (persona: Persona): boolean => {
		const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
		return Boolean(persona.isVurvey && getTime(persona.createdAt) >= Date.now() - THIRTY_DAYS);
	}

	const sortAgentSectionEntries = (
		[label]: [string, Persona[]],
		[label2]: [string, Persona[]]
	): number => label.localeCompare(label2);

	const personaSections = useMemo(() => {
		const sections: {label: string, personas: Persona[]}[] = [];

		const trending = filteredPersonas.filter(isTrending);

		if (trending.length) {
			sections.push({label: "🔥 Trending", personas: sortPersonas(trending)});
		}

		const sectionEntries = Object
			.entries(groupBy(filteredPersonas, "personaType.name"))
			.sort(sortAgentSectionEntries);

		for (
			const [label, personas] of sectionEntries) {
			sections.push({
				label: label.replace(" agent", ""),
				personas: sortPersonas(personas as Persona[])
			});
		}

		return sections;
	}, [filteredPersonas, sortPersonas]);

	const renderContent = (): ReactElement => {
		if (loading) return <LoadingContainer />;

		return (
			<div className={styles.agents}>
				{personaSections.map(({label, personas}, index) => (
					<AgentsSection
						key={index}
						personas={personas}
						headline={label}
						handleEdit={handleEdit}
						handleDelete={handleOpenDeleteModal}
					/>
				))}
			</div>
		);
	};

	const sortOptions: Option[] = useMemo(() => {
		return [
			{
				name: "Newest",
				id: "newest",
				label: "Newest",
			},
			{
				name: "Oldest",
				id: "oldest",
				label: "Oldest",
			}
		]
	}, [])

	const agentTypes = useMemo(() => {
		if (!agentTypesData) {
			return [];
		}

		return agentTypesData.aiPersonaTypes.map(({name}) => ({
			id: name,
			name: name.replace(" agent", ""),
		}));
	}, [agentTypesData])

	const modelOptions: Option[] = useMemo(() => {
		return [
			{
				name: "GPT",
				id: PersonaModel.GPT,
			},
			{
				name: "Gemini",
				id: PersonaModel.GEMINI,
			},
			{
				name: "Claude",
				id: PersonaModel.CLAUDE,
			},
			{
				name: "Stable Diffusion",
				id: PersonaModel.STABLE_DIFFUSION,
			},
			{
				name: "Imagen",
				id: PersonaModel.IMAGE_GEN,
			},
			{
				name: "DALL-E",
				id: PersonaModel.DALL_E,
			},

		]
	}, [])

	const statusOptions: Option[] = useMemo(() => {
		return [
			{
				name: "Active",
				id: "published",
				label: "Active",
			},
			{
				name: "Inactive",
				id: "draft",
				label: "Inactive",
			},
		]
	}, [])

	const handleSelectType = (value: string): void => {
		if (selectedTypes.includes(value)) {
			setSelectedTypes(selectedTypes.filter((type) => type !== value));
		} else {
			setSelectedTypes([...selectedTypes, value]);
		}
	}

	const handleSelectModel = (value: string): void => {
		if (selectedModels.includes(value)) {
			setSelectedModels(selectedModels.filter((model) => model !== value));
		} else {
			setSelectedModels([...selectedModels, value]);
		}
	}

	const handleSelectSort = (value: string): void => {
		if (sortValue !== value) {
			return setSortValue(value);
		}
	}

	const handleSelectStatus = (value: string): void => {
		if (selectedStatus === value) {
			return setSelectedStatus("");
		}
		return setSelectedStatus(value);
	}

	const handleSelectSkills = (value: string): void => {
		if (selectedSkills.includes(value)) {
			setSelectedSkills(selectedSkills.filter((skill) => skill !== value));
		} else {
			setSelectedSkills([...selectedSkills, value]);
		}
	}

	const [element, setElement] = useState<HTMLDivElement | null>(null);

	const callback = useCallback(([{isIntersecting, target}]: IntersectionObserverEntry[]) => {
		if (isIntersecting) {
			target.classList.remove(styles.isSticky);
		} else {
			target.classList.add(styles.isSticky);
		}
	}, []);

	useObserver(element, IntersectionObserver, callback, {threshold: 1});

	const stickyRef = useSameHeight(element);

	return (
		<div className={styles.container}>
			<div ref={stickyRef} className={styles.sticky}>
				<div
					ref={setElement}
					className={cx("filtersContainer", {isDarkMode})}
				>
					<div className={styles.filters}>
						<SelectInput
							className={styles.filter}
							options={sortOptions}
							placeholder="Sort"
							onChange={handleSelectSort}
							value={sortValue}
						/>
						<MultiSelectInput
							popupClassName={styles.typeFilterPopup}
							className={styles.filter}
							placeholder="Type"
							options={agentTypes}
							values={selectedTypes}
							onChange={handleSelectType}
						/>
						<MultiSelectInput
							className={styles.filter}
							placeholder="Model"
							options={modelOptions}
							values={selectedModels}
							onChange={handleSelectModel}
						/>
						<SelectInput
							className={styles.filter}
							placeholder="Status"
							options={statusOptions}
							value={selectedStatus}
							onChange={handleSelectStatus}
						/>
						<MultiSelectInput
							className={styles.filter}
							placeholder="Tags"
							options={tagsData?.aiSkills || []}
							values={selectedSkills}
							onChange={handleSelectSkills}
						/>
					</div>
					<SearchInput
						className={styles.searchInput}
						value={searchValue}
						onChange={handleSearchChange}
					/>
				</div>
			</div>

			{renderContent()}

			<ConfirmActionModal
				isOpen={Boolean(idForDelete)}
				onClose={() => setIdForDelete("")}
				onConfirm={handleDelete}
				title="Delete Agent"
				description="Do you really want to delete this agent? This action is permanent and cannot be undone."
				confirmText="Delete"
				cancelText="Cancel"
			/>

			<AgentDrawer />
		</div>
	)
};
